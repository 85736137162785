import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import theme from 'ui-kit/theme';
import { useOnClickOutside } from 'hooks';
import { DropdownFeatures } from 'utils/constants';

import {
  Container,
  InputFilterContainer,
  InputFilter,
  ClearInput,
  PositionedSearchIcon,
  DownCaret,
  Dropdown,
  SelectionList,
  SelectAllContainer,
  Option,
  IndentedOption,
} from '../StateJobBoardSharedComponents';

export function StateJobBoardDropDownWithSubOptions({
  values,
  setValues,
  options,
  displayName,
  isActive,
  setIsActive,
  mainColor = theme.uiColors.black,
  highlightColor = theme.colors.primary.light,
  showSearchInput = false,
  dataTestId,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const closeNodeOnClickOutside = useOnClickOutside(() => setShowOptions(false), showOptions);

  let filteredOptions = [];
  if (options) {
    options.forEach(option => {
      filteredOptions = filteredOptions.concat(option.subOptions);
    });

    if (searchValue) {
      filteredOptions = filteredOptions.filter(
        option => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      );
    }

    filteredOptions = [...new Set(filteredOptions.map(o => o.value))];
  }

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  };

  const allSubOptionsChecked = obj => {
    const itemIsCurrentlySelected = subOption => values.includes(subOption.value);

    return obj.subOptions.every(itemIsCurrentlySelected);
  };

  const updateSection = (e, section) => {
    e.stopPropagation();

    let updatedList = [...values];
    const isNotInSection = subOption => !section.subOptions.map(s => s.value).includes(subOption);

    if (!e.target.checked) {
      updatedList = updatedList.filter(isNotInSection);
    } else {
      section.subOptions.forEach(subOption => updatedList.push(subOption.value));
      updatedList = [...new Set(updatedList)];
    }

    updateValues(updatedList);
  };

  const selectAllValues = () => {
    const values = [];
    options.forEach(option => {
      option.subOptions.forEach(obj => {
        values.push(obj.value);
      });
    });

    setValues(values);
    setIsActive(false);
  };

  const toggleValue = (e, value) => {
    let updatedValues = [...values];

    if (!e.target.checked) {
      updatedValues = updatedValues.filter(item => item !== value);
    } else {
      updatedValues.push(value);
      // ensure no duplicates
      updatedValues = [...new Set(updatedValues)];
    }

    updateValues(updatedValues);
  };

  const updateValues = values => {
    let allSelected = true;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      for (let j = 0; j < option.subOptions.length; j++) {
        const subOption = option.subOptions[j];
        if (!values.includes(subOption.value)) {
          allSelected = false;
          break;
        }
      }
    }

    const noneSelected = values.length === 0;
    const isActive = !allSelected && !noneSelected;

    setValues(values);
    setIsActive(isActive);
  };

  return (
    <Container
      mainColor={mainColor}
      highlightColor={highlightColor}
      isActive={isActive}
      ref={closeNodeOnClickOutside}
    >
      <Dropdown onClick={toggleOptions} data-testid={dataTestId}>
        <p>{displayName}</p>
        <DownCaret fillColor={mainColor} />
      </Dropdown>
      <SelectionList
        showOptions={showOptions}
        data-testid={`${dataTestId}-${DropdownFeatures.SELECTION_LIST}`}
      >
        <SelectAllContainer>
          <span
            onClick={selectAllValues}
            data-testid={`${dataTestId}-${DropdownFeatures.SELECT_ALL}`}
          >
            Select All
          </span>
          <span
            onClick={() => updateValues([])}
            data-testid={`${dataTestId}-${DropdownFeatures.CLEAR_ALL}`}
          >
            Clear All
          </span>
        </SelectAllContainer>

        {showSearchInput && (
          <InputFilterContainer>
            <InputFilter
              type="text"
              placeholder=""
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              data-testid={`${dataTestId}-${DropdownFeatures.INPUT_FILTER}`}
            />
            {searchValue ? (
              <ClearInput
                onClick={() => setSearchValue('')}
                data-testid={`${dataTestId}-${DropdownFeatures.CLEAR_INPUT}`}
              >
                ×
              </ClearInput>
            ) : (
              <PositionedSearchIcon />
            )}
          </InputFilterContainer>
        )}

        {options.map(option => {
          if (option.subOptions.map(i => i.value).some(value => filteredOptions.includes(value))) {
            return (
              <Fragment key={option.value}>
                <Option data-testid={`${dataTestId}-${DropdownFeatures.OPTION}-${option.value}`}>
                  <label
                    className="container"
                    data-testid={`${dataTestId}-${DropdownFeatures.OPTION_LABEL}-${option.value}`}
                  >
                    <input
                      type="checkbox"
                      checked={allSubOptionsChecked(option)}
                      onChange={e => updateSection(e, option)}
                      data-testid={`${dataTestId}-${DropdownFeatures.OPTION_CHECKBOX}-${option.value}`}
                    />
                    <span className="checkmark"></span>
                    {option.label}
                  </label>
                </Option>
                {option.subOptions.map(subOption => {
                  // special case for grade early-childhood filter.
                  // Since there's only one sub option and it has the same
                  // label as the outer option, hide the sub option.
                  if (option.label === 'Early Childhood' && subOption.label === 'Early Childhood') {
                    return null;
                  }
                  if (filteredOptions.includes(subOption.value)) {
                    return (
                      <IndentedOption
                        key={subOption.value}
                        data-testid={`${dataTestId}-${DropdownFeatures.SUB_OPTION}-${subOption.value}`}
                      >
                        <label
                          className="container"
                          data-testid={`${dataTestId}-${DropdownFeatures.SUB_OPTION_LABEL}-${subOption.value}`}
                        >
                          <input
                            type="checkbox"
                            checked={values.includes(subOption.value)}
                            onChange={e => toggleValue(e, subOption.value)}
                            data-testid={`${dataTestId}-${DropdownFeatures.SUB_OPTION_CHECKBOX}-${subOption.value}`}
                          />
                          <span className="checkmark"></span>
                          {subOption.label}
                        </label>
                      </IndentedOption>
                    );
                  }
                  return null;
                })}
              </Fragment>
            );
          }
          return null;
        })}
      </SelectionList>
    </Container>
  );
}

StateJobBoardDropDownWithSubOptions.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  setValues: PropTypes.func.isRequired,
  options:
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        subOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
      })
    ) | PropTypes.boolean,
  displayName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
  highlightColor: PropTypes.string,
  showSearchInput: PropTypes.bool,
};
