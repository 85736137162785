import { Component } from 'react';
import { examType } from '../../../utils/enums';
import { handleOutsideClickOnValidForm, isCardBlank } from '../../../utils/util';

import PropTypes from 'prop-types';
import iconMinus from '../../../assets/icon-minus.svg';
import iconPlus from '../../../assets/icon-plus.svg';
import CardContainer from './CardContainer';
import ExamInputFields from './ExamTypes/ExamInputFields';
import _ from 'lodash';
import ErrorText from '../../../components/errortext';
import { ATSCandidateExamsDataTestIds } from 'data-testids/ATS';

export default class ExamInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exams: props.exams,
      addExam: props.freshObject(),
      isAdding: false,
      insertPosition: undefined,
      errorMessage: '',
    };
  }

  static propTypes = {
    exams: PropTypes.object.isRequired,
    /**
     * FreshObject is a template for an empty exam structure.
     */
    freshObject: PropTypes.func.isRequired,
    autosave: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ exams: nextProps.exams });
  }

  outsideClickListener = ev => {
    // If the element clicked is an SVG we will get an error if we access `indexOf`
    let className = '';
    if (ev.target instanceof SVGElement) {
      className = ev.target.getAttribute('class');
    } else {
      className = ev.target.className;
    }

    // Remove the listener if canceling out
    if (className?.indexOf('add-btn') !== -1) {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    // Don't need to handle the click if canceling out of the card
    if (className?.indexOf('add-btn') === -1) {
      handleOutsideClickOnValidForm(this.node, ev, this.toggleAdd);
    }
  };

  toggleAdd = () => {
    if (!this.state.isAdding) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      if (isCardBlank(this.state.addExam)) {
        return this.setState({
          errorMessage: 'You must complete at least one field to save this entry.',
        });
      }
      document.removeEventListener('click', this.outsideClickListener, false);
      this.handleAddRow();
    }
    this.setState({ isAdding: !this.state.isAdding, errorMessage: '' });
  };

  /* Updating the various input types */
  updateField = e => {
    var target = e.target;
    var name = target.name;
    var value = target.value;

    if (name === 'type') {
      value = parseInt(value, 10);
    }

    var updateObject = this.state.addExam;
    updateObject[name] = value;
    this.setState({ addExam: updateObject, errorMessage: '' });
  };

  handleAddRow = () => {
    let copyExams = Object.assign({}, this.state.exams);

    // insert at certain position if specified, otherwise add to end
    if (this.state.insertPosition !== undefined) {
      copyExams.list.splice(this.state.insertPosition, 0, this.state.addExam);
    } else {
      copyExams.list.push(this.state.addExam);
    }

    this.setState({ exams: copyExams, addExam: this.props.freshObject() }, () => {
      this.props.autosave();
    });
  };

  handleEditRow = (i, e) => {
    let copyExams = Object.assign({}, this.state.exams);
    let addNew = Object.assign({}, copyExams.list[i]);
    copyExams.list.splice(i, 1);
    // i represents the location of the (now editing) experience. Set it in state so we can use for insertion
    this.setState({ addExam: addNew, exams: copyExams, insertPosition: i }, () => {
      this.toggleAdd();
    });
  };

  handleRemoveRow = (i, e) => {
    e.stopPropagation();
    let copyExperiences = Object.assign({}, this.state.exams);
    copyExperiences.list.splice(i, 1);
    this.setState({ exams: copyExperiences }, () => {
      this.props.autosave();
    });
  };

  handleClick = (i, e) => {
    if (this.state.isAdding) {
      this.toggleAdd();
    } else {
      this.handleEditRow(i, e);
    }
  };

  // when adding a new row, insert at end of current list
  resetInsertPosition = () => {
    const insertPosition = this.state.exams.length;
    this.setState({ insertPosition });
  };

  render() {
    return (
      <div ref={node => (this.node = node)} className="exams">
        <div>
          {this.state.exams.list.map((row, i) => (
            <div className="added-row" key={i} onClick={e => this.handleClick(i, e)}>
              <div className="block flex-1">
                <CardContainer exam={row} />
              </div>
              <div className="pointer">
                <span className="mr1">Edit</span>
                <span onClick={e => this.handleRemoveRow(i, e)}>Delete</span>
              </div>
            </div>
          ))}
        </div>
        {this.state.isAdding && (
          <div className="application-row flex">
            <div className="basic-select dropdown">
              <select
                id="exam-type-select"
                data-testid={ATSCandidateExamsDataTestIds.EXAM_SELECT}
                name="type"
                value={typeof this.state.addExam.type === 'number' ? this.state.addExam.type : ''}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
              >
                <option value={-1}>Select type *</option>
                {examType().map((e, i) => (
                  <option
                    key={i}
                    data-testid={`${ATSCandidateExamsDataTestIds.EXAM_SELECT_DROPDOWN_OPTION}-${e.value}`}
                    value={Number(e.value)}
                  >
                    {e.label}
                  </option>
                ))}
              </select>
            </div>
            {_.includes([0, 1, 2, 3, 4, 5, 6, 7, 99], this.state.addExam.type) && (
              <ExamInputFields exam={this.state.addExam} handleChange={this.updateField} />
            )}
          </div>
        )}
        {this.state.isAdding ? (
          <div className="flex add-card-container">
            {this.state.insertPosition === undefined && (
              <div
                className="add-btn cancel"
                onClick={() => {
                  this.setState({
                    isAdding: false,
                    addExam: this.props.freshObject(),
                    errorMessage: '',
                  });
                }}
              >
                Cancel
              </div>
            )}
            <div
              className="add-btn save"
              data-testid={ATSCandidateExamsDataTestIds.SAVE_ENTRY_BUTTON}
              id="exams-save-button"
              onClick={() => {
                this.toggleAdd();
                this.resetInsertPosition();
              }}
            >
              Save Entry
            </div>
          </div>
        ) : (
          <div
            className="add-btn"
            data-testid={ATSCandidateExamsDataTestIds.ADD_EXAMS_BUTTON}
            id="exams-add-button"
            onClick={() => {
              this.toggleAdd();
              this.resetInsertPosition();
            }}
          >
            <img src={this.state.isAdding ? iconMinus : iconPlus} alt="Icon" />
            Add Entry
          </div>
        )}
        {this.state.errorMessage && <ErrorText message={this.state.errorMessage} />}
      </div>
    );
  }
}
