import { Stack, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OpenSans } from '../utils';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Link as RouterLink } from 'react-router-dom';
import ConnectGatedResultsAvatars from 'assets/connect/connect-gated-results-avatars.png';
import { nimbleTheme } from 'theme';
import { ConnectJobDiscoveryGatedResultsCardDataTestIds } from 'data-testids/ConnectDataTestIds';

export const GatedResultsCard: React.FC<{ count: number; numberOfSchoolsRendered: number }> = ({
  count,
  numberOfSchoolsRendered,
}) => {
  const getGatedCardHeaderText = count => {
    let headerText;
    const remainingCount = count - numberOfSchoolsRendered;

    if (count) {
      if (remainingCount > 1) {
        headerText = `Get started to view ${remainingCount} more schools that match your criteria`;
      } else if (remainingCount === 1) {
        headerText = `Get started to view ${remainingCount} more school that matches your criteria`;
      } else {
        headerText = `Get started to view more schools that match your criteria`;
      }
    }
    return headerText;
  };

  return (
    <GatedResultsCardContainer
      data-testid={ConnectJobDiscoveryGatedResultsCardDataTestIds.GATED_RESULTS_CARD}
    >
      <GatedResultsCardAvatars />
      <Stack gap={1}>
        <GatedResultsCardHeader
          data-testid={ConnectJobDiscoveryGatedResultsCardDataTestIds.HEADER_TEXT}
        >
          {getGatedCardHeaderText(count)}
        </GatedResultsCardHeader>
        <GatedResultsCardSubHeader>{`Skip the tedious applications and connect directly with principals who are hiring in your area`}</GatedResultsCardSubHeader>
      </Stack>
      <RouterLink to="/connect/signup" style={{ alignSelf: 'center' }}>
        <PrimaryButton
          size="small"
          dataTestId={ConnectJobDiscoveryGatedResultsCardDataTestIds.SIGNUP_BUTTON}
          sx={{
            alignSelf: 'center',
            background: nimbleTheme.palette.text.secondary,
            borderRadius: nimbleTheme.spacing(1),
            height: '44px',
            padding: '12px 20px',
            gap: nimbleTheme.spacing(1),
            width: '133px',
          }}
        >
          <GatedResultsCardSignUpButtonText>Sign up free</GatedResultsCardSignUpButtonText>
        </PrimaryButton>
      </RouterLink>
    </GatedResultsCardContainer>
  );
};

const GatedResultsCardContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  border: '1px solid #EAECF0',
  background: ' #FAFAFB',
  display: 'flex',
  padding: theme.spacing(4),
  alignContent: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
}));

const GatedResultsCardHeader = styled(Typography)(() => ({
  color: '#101828',
  textAlign: 'center',
  fontFamily: OpenSans,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '30px',
}));

const GatedResultsCardSubHeader = styled(Typography)(() => ({
  color: nimbleTheme.palette.text.light,
  textAlign: 'center',
  fontFamily: OpenSans,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
}));

const GatedResultsCardSignUpButtonText = styled(Typography)(() => ({
  color: '#FFF',
  fontFamily: OpenSans,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '24px',
}));

const GatedResultsCardAvatars = styled(Box)(() => ({
  alignSelf: 'center',
  backgroundImage: `url(${ConnectGatedResultsAvatars})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '120px',
  height: '56px',
}));
