/*
 * Let's follow this strategy to name our enums:
 * 1. System name as prefix: ATS, Connect
 * 2. Next: Page name, e.g.: Home, SignIn, JobsList
 * 3. Next: DataTestIds
 *
 * For each attribute name:
 * 1. Next: Element Identifier, e.g.: submit-, email-, password-
 * 2. Next: Element type, e.g.: button, input, text, link
 *
 * For the value, we must follow this rule:
 * data-testIds should follow the pattern area-noun-component (ex. connect-express-interest-primary-button)
 * More context here: https://nimble-ed.slack.com/archives/C06DZ7FF3RQ/p1721922036641099
 */

export enum ATSCandidateBasicInfoDataTestIds {
  ADDRESS_LINE1_INPUT = 'ats-candidate-basic-info-address-line1-input',
  CELL_PHONE_INPUT = 'ats-candidate-basic-info-cell-phone-input',
  CITY_INPUT = 'ats-candidate-basic-info-city-input',
  COMPLETE_PROFILE_TITLE = 'ats-candidate-basic-info-complete-profile-title',
  ETHNICITY_SELECT = 'ats-candidate-basic-info-ethnicity-select',
  GENDER_SELECT = 'ats-candidate-basic-info-gender-select',
  SAVE_AND_NEXT_BUTTON = 'ats-candidate-basic-info-save-and-next-button',
  UPLOAD_RESUME_INPUT = 'ats-candidate-basic-info-upload-resume-input',
  ZIP_INPUT = 'ats-candidate-basic-info-zip-input',
}

export enum ATSCandidateCredentialsDataTestIds {
  ADD_CREDENTIALS_BUTTON = 'ats-candidate-credentials-add-credentials-button',
  CREDENTIAL_TYPE_SELECT = 'ats-candidate-credentials-credentials-type-select',
  CREDENTIAL_TYPE_SELECT_DROPDOWN_OPTION = 'ats-candidate-credentials-credentials-type-select-dropdown-option',
  CREDENTIAL_STATE_SELECT = 'ats-candidate-credentials-credential-state-select',
  CREDENTIAL_CATEGORY_SELECT = 'ats-candidate-credentials-credential-category-select',
  SAVE_ENTRY_BUTTON = 'ats-candidate-credentials-save-entry-button',
}

export enum ATSCandidateExamsDataTestIds {
  ADD_EXAMS_BUTTON = 'ats-candidate-exams-add-exam-button',
  EXAM_SELECT = 'ats-candidate-exams-exam-select',
  EXAM_SELECT_DROPDOWN_OPTION = 'ats-candidate-exams-exam-select-dropdown-option',
  SAVE_ENTRY_BUTTON = 'ats-candidate-exams-save-entry-button',
}

export enum ATSCandidateExperienceDataTestIds {
  ADD_WORK_EXPERIENCE_BUTTON = 'ats-candidate-experience-add-work-experience-button',
  ADVANCED_DEGREE_YES_INPUT = 'ats-candidate-experience-advanced-degree-yes-input',
  EMPLOYER_INPUT = 'ats-candidate-experience-employer-input',
  END_MONTH_DATETIME = 'ats-candidate-experience-end-month-datetime',
  END_YEAR_DATETIME = 'ats-candidate-experience-end-year-datetime',
  EXPERIENCE_TYPE_DROPDOWN = 'ats-candidate-experience-experience-type-dropdown',
  HEAR_ABOUT_US_SELECT = 'ats-candidate-experience-hear-about-us-select',
  JOB_DESCRIPTION_INPUT = 'ats-candidate-experience-job-description-input',
  JOB_TITLE_INPUT = 'ats-candidate-experience-job-title-input',
  REASON_FOR_LEAVING_INPUT = 'ats-candidate-experience-reason-for-leaving-input',
  SAVE_AND_COMPLETE_BUTTON = 'ats-candidate-experience-save-and-complete-button',
  SAVE_AND_NEXT_STEP_BUTTON = 'ats-candidate-experience-save-and-next-step-button',
  SAVE_ENTRY_BUTTON = 'ats-candidate-experience-save-entry-button',
  START_MONTH_DATETIME = 'ats-candidate-experience-start-month-datetime',
  START_YEAR_DATETIME = 'ats-candidate-experience-start-year-datetime',
}

export enum ATSCandidateExperienceModalDataTestIds {
  IGNORE_AND_PROCEED_BUTTON = 'ats-candidate-experience-modal-ignore-and-proceed-button',
  MODAL_CONTAINER = 'ats-candidate-experience-modal-container',
}

export enum ATSCandidateEducationDataTestIds {
  ADD_EDUCATION_BUTTON = 'ats-candidate-education-add-education-button',
  DEGREE_SELECT_DROPDOWN = 'ats-candidate-education-degree-select-dropdown',
  DEGREE_SELECT_DROPDOWN_OPTION = 'ats-candidate-education-degree-select-dropdown-option',
  SAVE_ENTRY_BUTTON = 'ats-candidate-education-save-entry-button',
}

export enum ATSCandidateHomeDataTestIds {
  PROFILE_UPDATED_TITLE = 'ats-candidate-home-profile-updated-title',
  REMINDER_HEADER_TITLE = 'ats-candidate-home-reminder-header-title',
}

export enum ATSCandidateLanguagesDataTestIds {
  ADD_LANGUAGES_BUTTON = 'ats-candidate-languages-add-languages-button',
  LANGUAGE_SELECT = 'ats-candidate-languages-language-select',
  LANGUAGE_SELECT_DROPDOWN_OPTION = 'ats-candidate-languages-language-select-dropdown-option',
  PROFICIENCY_SELECT = 'ats-candidate-languages-proficiency-select',
  PROFICIENCY_SELECT_DROPDOWN_OPTION = 'ats-candidate-languages-proficiency-select-dropdown-option',
  SAVE_ENTRY_BUTTON = 'ats-candidate-languages-save-entry-button',
}

export enum ATSCandidatePreferencesDataTestIds {
  CATEGORIES_OF_INTEREST_MULTISELECT = 'ats-candidate-preferences-categories-of-interest-multiselect',
  COMPLETE_PROFILE_TEXT = 'ats-candidate-preferences-complete-profile-text',
  MAP_IMAGE = 'ats-candidate-preferences-map-image',
  SAVE_AND_NEXT_BUTTON = 'ats-candidate-preferences-save-and-next-button',
  STATE_PREFERENCES_MULTISELECT = 'ats-candidate-preferences-state-preferences-multiselect',
  TITLE_TEXT = 'ats-candidate-preferences-title-text',
}

export enum ATSCandidateReferencesDataTestIds {
  ADD_REFERENCES_BUTTON = 'ats-candidate-references-add-reference-button',
  FIRST_NAME_INPUT = 'ats-candidate-references-first-name-input',
  LAST_NAME_INPUT = 'ats-candidate-references-last-name-input',
  EMAIL_INPUT = 'ats-candidate-references-email-input',
  SAVE_ENTRY_BUTTON = 'ats-candidate-references-save-entry-button',
}

export enum ATSDataFFTDataTestIds {
  APPLICATION_FIELD_SELECT = 'ats-data-fft-application-field-select',
  CANDIDATE_PROFILE_SELECT = 'ats-data-fft-candidate-profile-select',
  COLUMN_NAME_INPUT = 'ats-data-fft-column-name-input',
  COLUMN_ROW = 'ats-data-fft-column-row',
  DATA_FORMAT_SELECT = 'ats-data-fft-data-format-select',
  DATA_SOURCE_SELECT = 'ats-data-fft-data-source-select',
  DELETE_ROW_BUTTON = 'ats-data-fft-delete-row-button',
  DOWNLOAD_TEST_BUTTON = 'ats-data-fft-download-test-button',
  FIELD_SELECT = 'ats-data-fft-field-select',
  NEW_COLUMN_BUTTON = 'ats-data-fft-new-column-button',
  NEW_DATA_TRANSFER_BUTTON = 'ats-data-fft-new-data-transfer-button',
  SAVE_BUTTON = 'ats-data-fft-save-button',
}

export enum ATSDistrictHomeDataTestIds {
  GREETING_TEXT = 'ats-home-greeting-text',
  HIGH_RETENTION = 'ats-home-high-retention',
  HIGHLY_SKILLS_TEXT = 'ats-home-highly-skills-text',
  TOP_CANDIDATE_CONTAINER = 'ats-home-top-candidate-container',
  TOP_CANDIDATE_JOB_TEXT = 'ats-home-top-candidate-job-text',
  TOP_CANDIDATE_NAME_TEXT = 'ats-home-top-candidate-name-text',
}

export enum ATSDistrictJobListAlertBannerDataTestId {
  ALERT_BANNER = 'ats-district-job-list-alert-banner',
}

export enum ATSDistrictJobListDataTestIds {
  JOB_ROW = 'ats-district-job-board-job-row',
  JOB_TITLE = 'ats-district-job-board-job-row-job-title',
  COLUMN_HEADER = 'ats-district-job-board-job-list-column-header',
  COLUMN_NAME = 'ats-district-job-board-job-list-column-header-column-name-text',
}

export enum ATSDistrictJobsListFiltersDataTestIds {
  CATEGORY_FILTER = 'ats-district-job-list-category-filter-dropdown',
  EMPLOYMENT_TYPE_FILTER = 'ats-district-job-list-employment-type-filter-dropdown',
  GRADE_LEVEL_FILTER = 'ats-district-job-list-grade-level-filter-dropdown',
  SCHOOL_FILTER = 'ats-district-job-list-school-filter-dropdown',
  SEARCH_INPUT = 'ats-district-job-list-search-input',
  SEARCH_SUBMIT_BUTTON = 'ats-district-job-list-filter-search-submit-button',
}

export enum ATSDistrictJobsListCategoryFilterDataTestIds {
  ALL_CATEGORIES_OPTION = 'ats-district-job-list-category-filter-all-categories-option',
  CATEGORY_OPTION = 'ats-district-job-list-category-filter-categories-option',
  SUBCATEGORY_OPTION = 'ats-district-job-list-category-filter-subcategory-option',
}

export enum ATSDistrictJobsListGradeLevelFilterDataTestIds {
  ALL_GRADES_OPTION = 'ats-district-job-list-grade-level-filter-all-grades-option',
  GRADE_OPTION = 'ats-district-job-list-category-filter-grade-option',
}

export enum ATSJobClosureNotificationTestIds {
  ADD_NOTIFICATION_BUTTON = 'ats-job-closure-add-notification-button',
  ALERT_BANNER = 'ats-job-closure-alert-banner',
  APPLICATION_STATUS_ID_ERROR_TEXT = 'ats-job-closure-modal-application-status-id-error',
  DELETE_NOTIFICATION_BUTTON = 'ats-job-closure-delete-notifications-button',
  EDIT_NOTIFICATION_BUTTON = 'ats-job-closure-edit-notifications-button',
  HEADING = 'ats-job-closure-heading',
  JOB_CLOSURE_SECTION = 'ats-job-closure-notifications-section',
  MODAL = 'ats-job-closure-modal',
  MODAL_PRIMARY_BUTTON = 'ats-job-closure-modal-primary-button',
  MODAL_TITLE = 'ats-job-closure-modal-title',
  THRESHOLD_ERROR_TEXT = 'ats-job-closure-modal-threshold-error',
  SUBSCRIBERS_ERROR_TEXT = 'ats-job-closure-modal-subscribers-error',
}

export enum ATSJobViewDataTestIds {
  APPLY_BUTTON = 'ats-job-view-apply-button',
  DETAILS_CONTAINER = 'ats-job-view-details-container',
  TITLE_TEXT = 'ats-job-view-title-text',
}

export enum ATSJobsListDataTestIds {
  CATEGORY_CLEARALL = 'ats-jobs-list-categories-clearall',
  CATEGORY_DROPDOWN = 'ats-jobs-list-categories-dropdown',
  CATEGORY_OPTION = 'ats-jobs-list-categories-option',
  COUNT_TEXT = 'ats-jobs-list-count-text',
  FILTER_CONTAINER = 'ats-jobs-list-filter-container',
  ROW_CONTAINER = 'ats-jobs-list-row-container',
  ROW_DATE_TEXT = 'ats-jobs-list-row-date-text',
  ROW_TITLE_TEXT = 'ats-jobs-list-row-title-text',
  SEARCH_INPUT = 'ats-jobs-list-search-input',
}

export enum ATSNavbarDataTestIds {
  JOBS_DROPDOWN = 'ats-navbar-jobs-dropdown',
  JOBS_LINK = 'ats-navbar-jobs-link',
  TEMPLATES_LINK = 'ats-navbar-templates-link',
}

export enum ATSReviewApplicationTestIds {
  RESUME_FILE_TITLE = 'ats-experience-resume-file-title',
  SUBMIT_APPLICATION_BUTTON = 'ats-experience-submit-application-button',
}

export enum ATSSignInDataTestIds {
  EMAIL_INPUT = 'ats-sign-in-email-input',
  PASSWORD_INPUT = 'ats-sign-in-password-input',
  SUBMIT_BUTTON = 'ats-sign-in-submit-button',
}

export enum ATSSignUpDataTestIds {
  ACTIVATE_BUTTON = 'ats-sign-up-activate-button',
  EMAIL_CONFIRM_INPUT = 'ats-sign-up-email-confirm-input',
  EMAIL_INPUT = 'ats-sign-up-email-input',
  ERROR_TEXT = 'ats-sign-up-error-text',
  FIRST_NAME_INPUT = 'ats-sign-up-first-name-input',
  GOOGLE_SIGN_UP_BUTTON = 'ats-sign-up-google-sign-up-button',
  LAST_NAME_INPUT = 'ats-sign-up-last-name-input',
  PASSWORD_CONFIRM_INPUT = 'ats-sign-up-password-confirm-input',
  PASSWORD_INPUT = 'ats-sign-up-password-input',
  SIGN_UP_CONTAINER = 'ats-sign-up-container',
  SUBMIT_BUTTON = 'ats-sign-up-submit-button',
}

export enum ATSStateJobBoardAlertsModalDataTestIds {
  CATEGORY_DROPDOWN = 'ats-state-job-board-alerts-modal-category-dropdown',
  ORGANIZATION_DROPDOWN = 'ats-state-job-board-alerts-modal-organization-dropdown',
}

export enum ATSStateJobBoardFiltersDataTestIds {
  CATEGORY_FILTER = 'ats-state-job-board-category-filter-dropdown',
  EMPLOYMENT_TYPE_FILTER = 'ats-state-job-board-employment-type-filter-dropdown',
  GRADE_LEVEL_FILTER = 'ats-state-job-board-grade-level-filter-dropdown',
  ORGANIZATION_FILTER = 'ats-state-job-board-organization-filter-dropdown',
  SCHOOL_FILTER = 'ats-state-job-board-school-filter-dropdown',
  SEARCH_INPUT = 'ats-state-job-board-search-input',
  START_DATE_FILTER = 'ats-state-job-board-start-date-filter-dropdown',
}

export enum ATSStateJobBoardHeaderDataTestIds {
  JOB_COUNT = 'ats-state-job-board-header-job-count-text',
  SEARCH_INPUT = 'ats-state-job-board-header-search-input',
  SEARCH_SUBMIT_BUTTON = 'ats-state-job-board-header-search-submit-button',
}

export enum ATSStateJobBoardJobListDataTestIds {
  JOB_ROW = 'ats-state-job-board-job-row',
  JOB_TITLE = 'ats-state-job-board-job-row-job-title',
}

export enum ATSStateJobBoardTalentModalDataTestIds {
  NO_THANKS_BUTTON = 'ats-state-job-board-talent-modal-no-thanks-button',
}

export enum ATSTemplatesListDataTestIds {
  SEARCH_INPUT = 'ats-templates-list-search-input',
}

export enum ATSVacancyDashboardDataTestIds {
  CATEGORIES_MULTISELECT = 'ats-vacancy-dashboard-categories-multiselect',
  CLEAR_ALL_BUTTON = 'ats-vacancy-dashboard-clear-all-button',
  DOWNLOAD_RAW_DATA_BUTTON = 'ats-vacancy-dashboard-download-raw-data-button',
  DROPDOWN_CLEAR_BUTTON = 'ats-vacancy-dashboard-dropdown-clear-button',
  DROPDOWN_SELECT_ALL_BUTTON = 'ats-vacancy-dashboard-dropdown-select-all-button',
  HIRING_SEASON_MULTISELECT = 'ats-vacancy-dashboard-hiring-season-multiselect',
  JOB_POSTINGS_MULTISELECT = 'ats-vacancy-dashboard-job-postings-multiselect',
  SCHOOLS_MULTISELECT = 'ats-vacancy-dashboard-schools-multiselect',
  VACANCY_ROW = 'ats-vacancy-dashboard-vacancy-row',
  VACANCY_ROW_CANDIDATE_TITLE = 'ats-vacancy-dashboard-vacancy-candidate-title',
  VACANCY_ROW_JOB_TITLE = 'ats-vacancy-dashboard-vacancy-row-title',
}
