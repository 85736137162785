import { useState, useEffect, useRef, ChangeEvent } from 'react';
import styled from 'styled-components';

import { languageList, languageProficiency } from 'utils/enums';
import { handleOutsideClickOnValidForm } from 'utils/util';
import { Dropdown, theme } from 'ui-kit';

import iconMinus from 'assets/icon-minus.svg';
import iconPlus from 'assets/icon-plus.svg';
import ErrorText from '../../errortext';
import { ATSCandidateLanguagesDataTestIds } from 'data-testids/ATS';

const getItem = (list: { value: number; label: string }[], value: number): string => {
  const option = list.find(o => o.value === value);
  return option ? option.label : '';
};

interface Language {
  language: number;
  status: number;
}

interface Languages {
  list: Language[];
}

interface LanguageInputsProps {
  languages: Languages;
  freshObject: () => Language;
  autosave: () => void;
}

const LanguageInputs: React.FC<LanguageInputsProps> = ({ languages, freshObject, autosave }) => {
  const [localLanguages, setLocalLanguages] = useState<Languages>(languages);
  const [addLanguage, setAddLanguage] = useState<Language>(freshObject());
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [insertPosition, setInsertPosition] = useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [languageError, setLanguageError] = useState<boolean>(false);
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocalLanguages(languages);
  }, [languages]);

  const toggleAdd = () => {
    if (!isAdding) {
      document.addEventListener('click', outsideClickListener, { capture: true });
      resetInsertPosition();
    } else {
      document.removeEventListener('click', outsideClickListener, false);
    }
    setIsAdding(!isAdding);
    setErrorMessage('');
    setLanguageError(false);
  };

  const outsideClickListener = (ev: MouseEvent) => {
    let className = '';
    if (ev.target instanceof SVGElement) {
      className = ev.target.getAttribute('class') ?? '';
    } else if (ev.target instanceof HTMLElement) {
      className = ev.target.className;
    }

    if (className.indexOf('add-btn') !== -1) {
      document.removeEventListener('click', outsideClickListener, { capture: true });
    }
    if (className.indexOf('add-btn') === -1) {
      handleOutsideClickOnValidForm(node.current, ev, toggleAdd);
    }
  };

  const handleAddRow = () => {
    if (!validateLanguageSelection()) return;

    const copyLanguages = { ...localLanguages };

    if (insertPosition !== undefined) {
      copyLanguages.list.splice(insertPosition, 0, addLanguage);
    } else {
      copyLanguages.list.push(addLanguage);
    }

    setLocalLanguages(copyLanguages);
    setAddLanguage(freshObject());
    setLanguageError(false);
    setIsAdding(false);
    resetInsertPosition();
    document.removeEventListener('click', outsideClickListener, false);
    autosave();
  };

  const updateField = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAddLanguage(prevAddLanguage => ({ ...prevAddLanguage, [name]: value }));
    setErrorMessage('');
  };

  const handleEditRow = (i: number) => {
    const copyLanguages = { ...localLanguages };
    const addNew = { ...copyLanguages.list[i] };
    copyLanguages.list.splice(i, 1);
    setAddLanguage(addNew);
    setLocalLanguages(copyLanguages);
    setInsertPosition(i);
    toggleAdd();
  };

  const handleRemoveRow = (i: number, e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    const copyLanguages = { ...localLanguages };
    copyLanguages.list.splice(i, 1);
    setLocalLanguages(copyLanguages);
    autosave();
  };

  const resetInsertPosition = () => {
    setInsertPosition(localLanguages.list.length);
  };

  const validateLanguageSelection = (): boolean => {
    if (addLanguage.language === -1) {
      setLanguageError(true);
      return false;
    }
    setLanguageError(false);
    setErrorMessage('');
    return true;
  };

  return (
    <div ref={node}>
      {localLanguages.list.map((row, i) => (
        <div className="added-row" key={i} onClick={() => handleEditRow(i)}>
          <span className="flex-2">{getItem(languageList(), row.language)}</span>
          <span className="flex-2">{getItem(languageProficiency(), row.status)}</span>
          <div className="pointer">
            <span className="mr1">Edit</span>
            <span onClick={e => handleRemoveRow(i, e)}>Delete</span>
          </div>
        </div>
      ))}

      {isAdding && (
        <div className="basic application-row flex space-between">
          <div className="flex-1">
            <LanguageSelect
              name="language"
              dataTestId={ATSCandidateLanguagesDataTestIds.LANGUAGE_SELECT}
              id="language-select"
              value={addLanguage.language}
              onChange={updateField}
              options={languageList()}
              placeholder="Select Language"
              isError={languageError}
              isRequired
            />
          </div>
          <div className="ml1 flex-1">
            <LanguageSelect
              dataTestId={ATSCandidateLanguagesDataTestIds.PROFICIENCY_SELECT}
              name="status"
              id="language-status-select"
              value={addLanguage.status}
              onChange={updateField}
              options={languageProficiency()}
              placeholder="Select Proficiency"
              isError={false}
            />
          </div>
        </div>
      )}

      {isAdding ? (
        <AddCancelButtons
          onCancel={() => {
            setIsAdding(false);
            setAddLanguage(freshObject());
            setErrorMessage('');
            setLanguageError(false);
            resetInsertPosition();
            document.removeEventListener('click', outsideClickListener, false);
          }}
          onSave={handleAddRow}
        />
      ) : (
        <AddButton onClick={toggleAdd} isAdding={isAdding} />
      )}

      {errorMessage && <ErrorText message={errorMessage} />}
    </div>
  );
};

const LanguageSelect = ({
  name,
  id,
  value,
  onChange,
  options,
  placeholder,
  isError = false,
  isRequired = false,
  dataTestId,
}) => {
  const [selectedLabel, setSelectedLabel] = useState(placeholder);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const selectedOption = options.find(option => option.value === value);
    setSelectedLabel(selectedOption ? selectedOption.label : placeholder);
    setIsActive(!!selectedOption);
  }, [value, options, placeholder]);

  const handleDropdownChange = selectedOption => {
    setSelectedLabel(selectedOption.label);
    setIsActive(true);
    onChange({ target: { name, value: selectedOption.value } });
  };

  return (
    <Dropdown.Dropdown
      dataTestId={dataTestId}
      label={selectedLabel}
      labelProps={{}}
      inputProps={{ paddingLeft: '1.5em', paddingRight: '1.5em', isError: isError, id: id }}
      isActive={isActive}
      listProps={{}}
      isRequired={isRequired}
      isMultiSelect={false}
    >
      {options.map(option => (
        <ListItem
          key={option.value}
          data-testid={`${dataTestId}-dropdown-option-${option.value}`}
          onClick={() => handleDropdownChange(option)}
        >
          {option.label}
        </ListItem>
      ))}
    </Dropdown.Dropdown>
  );
};

const AddCancelButtons = ({ onCancel, onSave }) => (
  <div className="flex add-card-container">
    <div className="add-btn cancel" onClick={onCancel}>
      Cancel
    </div>
    <div
      className="add-btn save"
      onClick={onSave}
      data-testid={ATSCandidateLanguagesDataTestIds.SAVE_ENTRY_BUTTON}
      id="language-save-button"
    >
      Save Entry
    </div>
  </div>
);

const AddButton = ({ onClick, isAdding }) => (
  <div
    className="add-btn"
    onClick={onClick}
    data-testid={ATSCandidateLanguagesDataTestIds.ADD_LANGUAGES_BUTTON}
    id="language-add-button"
  >
    <img src={isAdding ? iconMinus : iconPlus} alt="Icon" />
    {isAdding ? 'Cancel' : 'Add Entry'}
  </div>
);

export default LanguageInputs;

const DropdownListItem = ({ closeDropdown, onClick, children, ...rest }) => {
  const handleClick = () => {
    if (onClick) onClick();
    closeDropdown();
  };

  return (
    <Dropdown.ListItem {...rest} onClick={handleClick}>
      <span>{children}</span>
    </Dropdown.ListItem>
  );
};

const ListItem = styled(DropdownListItem)`
  padding: 0.5em 1.5em;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.isSelected ? theme.uiColors.white : 'default')};
  background-color: ${props => (props.isSelected ? theme.uiColors.greens.full : 'transparent')};

  :hover {
    color: ${theme.uiColors.white};
    background-color: ${theme.uiColors.greens.medium};
  }
`;
