import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import { SchoolCardProps } from '../types';
import { VacanciesSectionButtonRows } from '../VacancySection/VacancySectionButtonRow';
import { BasicInfoSection } from './SchoolCardBasicInfoSection';
import { Header, MobileHeader } from './SchoolCardHeaderSection';
import { ConnectJobDiscoverySchoolCardDataTestIds } from 'data-testids/ConnectDataTestIds';
export const SchoolCard: React.FC<SchoolCardProps> = ({ school, actions }) => {
  return (
    <>
      <SchoolCardContainer
        width={'100%'}
        data-testid={ConnectJobDiscoverySchoolCardDataTestIds.SCHOOL_CARD}
      >
        <Hidden smDown>
          <Header school={school} actions={actions} />
        </Hidden>
        <Hidden smUp>
          <MobileHeader school={school} actions={actions} />
        </Hidden>
        <BasicInfoSection school={school} actions={actions} />
        <VacanciesSectionButtonRows
          vacancies={school?.jobpostings}
          school={school}
          actions={actions}
        />
      </SchoolCardContainer>
    </>
  );
};

export const SchoolCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
  border: '1px solid #EAECF0',
  gap: '8px',
  background: '#FFF',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px 16px',
  },
}));
